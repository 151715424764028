import { required, email } from "./field-rules";
import { password } from "./field-icons";

export default [
  {
    name: "typeAppeal",
    attrs: {
      type: "select",
      label: "Вид обращения",
      required: true
    },
    rules: [required]
  },
  {
    name: "typeAppeal2",
    attrs: {
      type: "select",
      label: "Вопрос",
      required: false
    },
  },
  {
    name: "accountingPointNumber",
    attrs: {
      type: "select",
      label: "Точка учета",
      required: false
    }
  },
  {
    name: "email",
    attrs: {
      type: "text",
      label: "Email",
      required: true
    },
    rules: [required, email]
  },

  {
    name: "message",
    attrs: {
      type: "textarea",
      label: "Текст обращения",
      required: true
    },
    rules: [required]
  },
  {
    name: "file",
    attrs: {
      type: "file",
      label: "Прикрепить файл",
      required: false
    }
  },

];
