











































import {Component, Emit, Watch} from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class TableDropdown extends XDropdown {
  selectedAll = true;
  selectedCheckboxes: boolean[] = this.items.map(() => true);

  public get isAloneSelectedCheckbox(): boolean {
    return (
      this.selectedCheckboxes.indexOf(true) ===
      this.selectedCheckboxes.lastIndexOf(true)
    );
  }

  @Watch("items")
  public itemsChanged() {
    this.selectedCheckboxes = this.items.map(() => true);
  }

  @Emit("click")
  emitToggleCheckboxEvt(): void {
    this.$emit("update:column", this.selectedCheckboxes);
  }

  public checkedAll() {
    this.selectedCheckboxes = this.selectedCheckboxes.map(() => true);
    this.emitToggleCheckboxEvt();
  }

  public onCheckbox() {
    this.selectedAll = !this.selectedCheckboxes.some((checkbox) => !checkbox);
    this.emitToggleCheckboxEvt();
  }

  public itemChanged() {
      console.log(this.items);
  }
}

export default TableDropdown;
