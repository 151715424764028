import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import Enviroment from "@/models/enviroment";

@Module({ namespaced: true })
class EnviromentModule extends VuexModule {
  constants: Enviroment | null = null;

  public get valuesInputDisabled(): boolean {
    const { constants } = this;

    const dates: number[] | null =
      constants &&
      ["ЮЛ_САЙТПОКАЗАНИЯОТ", "ЮЛ_САЙТПОКАЗАНИЯДО"].map(
        constantKey => constants[constantKey]
      );

    if (dates) {
      const day = new Date().getDate();
      if (dates[0] > dates[1]) {
        return !(day >= dates[0] || day <= dates[1]);
      } else {
        return !(day >= dates[0] && day <= dates[1]);
      }
    }

    return false;
  }

  @Mutation
  setConstants(constants: Enviroment) {
    this.constants = constants;
  }
}

export default EnviromentModule;
