import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  Organization,
  OrganizationContact,
  OrganizationPaymentAccount,
  OrganizationAgent
} from "@/models/organization";

@Module({ namespaced: true })
class OrganizationModule extends VuexModule {
  organization: Organization | null = null;

  contacts: OrganizationContact[] | null = null;
  paymentAccounts: OrganizationPaymentAccount[] | null = null;
  agents: OrganizationAgent[] | null = null;

  public get id(): number | null {
    return this.organization && this.organization["$номерЗаписи"];
  }
  public get nameOrg(): string | null {
    return this.organization && this.organization["название"];
  }

  public get contactList(): OrganizationContact[] {
    return Array.isArray(this.contacts) ? this.contacts : [];
  }

  public get paymentAccountList(): OrganizationPaymentAccount[] {
    return Array.isArray(this.paymentAccounts) ? this.paymentAccounts : [];
  }

  public get agentList() {
    //return Array.isArray(this.agents) ? this.agents : [];
    return this.agents;
  }

  @Mutation
  setOrganization(organization: Organization | null) {
    this.organization = organization;
  }

  @Mutation
  setContacts(contacts: OrganizationContact[] | null) {
    this.contacts = contacts;
  }

  @Mutation
  setPaymentAccounts(accounts: OrganizationPaymentAccount[] | null) {
    this.paymentAccounts = accounts;
  }

  @Mutation
  setAgents(agents: []) {
    this.agents = agents;
  }
}

export default OrganizationModule;
