


































import { Component, Mixins, Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XDialog from "../SimpleDialog.vue";
import XButton from "../SimpleButton.vue";
import { confirmCountersNewValueForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";

const { values, attrs, errors, validations } = confirmCountersNewValueForm;

@Component({
  components: { XDialog, XButton },
  mixins: [validationMixin],
  computed: {
    ...errors
  },
  validations,
})
class ConfirmActionDialog extends Mixins(AppApiMixin, XDialog) {
  @Prop({ required: true }) showDialog!: boolean;
  @Prop({ required: true }) readonly indication!: number;
  @Prop({ required: true }) readonly tarif!: number;
  @Prop({ required: true }) readonly meteringPointId!: number;
  @Prop({ required: true }) readonly counterId!: number;


  values = { counterType: "" };
  allFieldAttrs = {
    counterType: {
      type: "select",
      label: "",
      required: true,
      items: ['Отрицательный расход', 'Перекрутка счетчика'],
    },
  };
  useAgreeButtonLoading = false;
  public onAgree(evt: { name: string; payload: null }) {
    this.useAgreeButtonLoading = true;
    const {
      meteringPointId,
      counterId,
      tarif,
      sendCountersNewValue,
      indication,
      values
    } = this;

    sendCountersNewValue(
      meteringPointId,
      counterId,
      tarif,
      indication,
      values.counterType === 'Перекрутка счетчика' ? 1 : 0
    )
      .then(() => {
        this.$emit(evt.name);
      })
      .catch((err: string) => {
        this.$emit('error');
        this.resetData();
        this.closeAllDialogs();
      })
      .finally(() => (this.useAgreeButtonLoading = false));
  }

  public closeAllDialogs() {
    this.useAgreeButtonLoading = false;
  }

  resetData(){
    this.values.counterType = '';
    this.$v.$reset();
  }

}
export default ConfirmActionDialog;
