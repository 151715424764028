import {required, email, checkbox, amount} from "./field-rules";

export default [
  {
    name: "allowDataHandling",
    attrs: {
      type: "checkbox",
      label: "Я подтверждаю данные платежа",
      required: true
    },
    rules: [required, checkbox]
  },
  {
    name: "amount",
    attrs: {
      type: "currency",
      label: "Сумма платежа",
      required: true
    },
    rules: [required, amount]
  },
  {
    name: "total",
    attrs: {
      type: "currency",
      label: "Итого к оплате",
      required: true,
      disabled: true
    },
    rules: [ required ]
  },
  {
    name: "email",
    attrs: {
      type: "text",
      label: "Email для отправки чека",
      required: true
    },
    rules: [required, email]
  }
];
