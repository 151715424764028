import { required } from "./field-rules";

export default [
  {
    name: "typeCounter",
    attrs: {
      type: "select",
      label: "",
      required: true
    },
    rules: [ required ]
  }
];
