import { required, email } from "./field-rules";
import { password } from "./field-icons";

export default [
    {
    name: "message",
    attrs: {
      type: "textarea",
      label: "",
      required: true
    },
    rules: [required]
  },
  {
    name: "file",
    attrs: {
      type: "file",
      label: "",
      required: false
    }
  },

];


