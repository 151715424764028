





import { Component, Vue } from "vue-property-decorator";

@Component
class AppealsForRoot extends Vue {

}

export default AppealsForRoot;
