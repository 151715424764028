import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AppealsByContract, AppealThemes, AppealThemeTypes, AppealChatMessages } from "@/models/appeals";
import { store } from "@/store/store";
import App from "@/App.vue";


@Module({ namespaced: true })
class AppealsModule extends VuexModule {
  [x: string]: any

  listAppeals: AppealsByContract[] | null = null;
  listThemes: AppealThemes[] | null = null;
  listThemeTypes: AppealThemeTypes[] | null = null;
  listAppealHistory: AppealChatMessages[] | null = null;

  currentAppealInfo: [];
  unreadCounterAppeals: [] | null = null;

  // темы обращений
  public get themeName() {
    return this.listThemes;
  }
  public get themeId() {
    return this.listThemes && this.listThemes["обращение-вид"];
  }
  public get themeTypes() {
    return this.listThemeTypes;
  }
  public get themeTypesId() {
    return this.listThemeTypes && this.listThemeTypes["$номерЗаписи"];
  }
  public get appealHistory() {
    return this.listAppealHistory;
  }
  public get getCurrentAppealInfo() {
    return this.currentAppealInfo;
  }
  // Обращения
  public get appealsList() {
    return this.listAppeals;
  }

  public get unreadAppealsCount() {
    return this.unreadCounterAppeals;
  }

  @Mutation
  setThemes(appealThemes: []) {
    this.listThemes = appealThemes;
  }

  @Mutation
  setThemeTypes(appealThemeTypes: AppealThemeTypes[] | null) {
    this.listThemeTypes = appealThemeTypes;
  }

  @Mutation
  setAppealsByContract(appealsByContract: AppealsByContract[]) {
    this.listAppeals = appealsByContract;
  }
  @Mutation
  setAppealHistory(appealChat: AppealChatMessages[]) {
    this.listAppealHistory = appealChat;
  }
  @Mutation
  setCurrentAppealInfo(appealInfo: []) {
    this.currentAppealInfo = appealInfo;
  }
  @Mutation
  setUnreadCounterValue(appealId: []) {
    this.unreadCounterAppeals = appealId;
  }

}

export default AppealsModule;
