import signInFormFields from "./sign-in-form";
import signUpFormFields from "./sign-up-form";
import countersNewValueFormFields from "./counters-new-value";
import passwordRecoveryFormFields from "./password-recovery-form";
import contractAddingFormFields from "./contract-adding-form";
import emailReplacementFormFields from "./email-replacement-form";
import passwordReplacementFormFields from "./password-replacement-form";
import documentGetterFormFields from "./document-getter-form";
import datePickerFormFields from "./date-picker-form";
import hourlyGetterFormFields from "./hourly-getter-form";
import datePickerAsSingleField from "./date-picker-as-single-field";
import accountingPointPlanFormFields from "./accounting-point-plan-form";
import confirmActionFormFields from "./confirm-action-form";
import confirmCountersNewValueFields from "./confirm-counters-new-values";
import appealFormFields from "./appeal-form";
import appealChatFormFields from "./appeal-chat-form";
import paymentFormFields from "./payment-form";
import paymentNoAuthFormFields from "../form-fields/payment-no-auth-form";

import { prepareFieldsForComponent } from "@/assets/scripts/form-utils";

const forms = [
  signInFormFields,
  signUpFormFields,
  countersNewValueFormFields,
  passwordRecoveryFormFields,
  contractAddingFormFields,
  emailReplacementFormFields,
  passwordReplacementFormFields,
  documentGetterFormFields,
  datePickerFormFields,
  datePickerAsSingleField,
  accountingPointPlanFormFields,
  confirmActionFormFields,
  appealFormFields,
  hourlyGetterFormFields,
  confirmCountersNewValueFields,
  appealChatFormFields,
  paymentFormFields,
  paymentNoAuthFormFields,
];

// Поля ввода, подготовленные к использованию в рамках соответствующих компонентов.
const [
  signInForm,
  signUpForm,
  countersNewValueForm,
  passwordRecoveryForm,
  contractAddingForm,
  emailReplacementForm,
  passwordReplacementForm,
  documentGetterForm,
  datePickerForm,
  datePickerFormAsSingleField,
  accountingPointPlanForm,
  confirmActionForm,
  appealForm,
  hourlyGetterForm,
  confirmCountersNewValueForm,
  appealChatForm,
  paymentForm,
  paymentNoAuthForm,
] = forms.map(formFields => prepareFieldsForComponent(formFields));

export {
  signInForm,
  signUpForm,
  countersNewValueForm,
  passwordRecoveryForm,
  contractAddingForm,
  emailReplacementForm,
  passwordReplacementForm,
  documentGetterForm,
  datePickerForm,
  datePickerFormAsSingleField,
  accountingPointPlanForm,
  confirmActionForm,
  appealForm,
  hourlyGetterForm,
  confirmCountersNewValueForm,
  appealChatForm,
  paymentForm,
  paymentNoAuthForm,
};
