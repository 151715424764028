
























import { Component } from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class AccountDropdown extends XDropdown {
  itemProps = [
    {
      icon: "mdi-cog",
      title: "Настройки",
      handler: () => {
        const routeName = "accountSettings";
        this.$route.name !== routeName &&
          this.$router.push({ name: routeName });
      },
    },
    {
      icon: "mdi-help-circle",
      title: "Помощь",
      handler: () => {
        window.open(
          "https://help.stack-it.ru/services/lk_ul/lk_ul_user/1_web_tk_ul_lk.html#%D1%87%D1%82%D0%BE-%D1%82%D0%B0%D0%BA%D0%BE%D0%B5-%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B8-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D0%B8-%D0%B7%D0%B0%D1%87%D0%B5%D0%BC-%D0%BE%D0%BD-%D0%BD%D1%83%D0%B6%D0%B5%D0%BD",
          "_blank"
        );
      },
    },
    { isDivider: true },
    {
      icon: "mdi-logout-variant",
      title: "Выход",
      handler: () => this.$emit("logout"),
    },
  ];
}

export default AccountDropdown;
