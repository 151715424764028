/// <reference path="typings.d.ts" />

import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store/store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import "./styles/index.scss";

Vue.config.productionTip = false;

Vue.use(VueMask);

import { VTextField } from 'vuetify/lib'  //Globally import VTextField
Vue.component('v-text-field', VTextField)
// @ts-ignore
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
  locale: 'ru-RU',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
